import "./HomePage.css";
import React from "react";


function HomePage() {

    return (
        <div>
            <div className="mb-3 mt-6 body-text">
                Welcome to Shadowdark Tools, a set of handy tools for GMs and Players of the <a className="regular" href="https://www.thearcanelibrary.com/pages/shadowdark">Shadowdark</a> roleplaying game.
            </div>
            <div>
                <div>
                    <h2>What To Expect</h2>
                </div>
                <div>
                    <ul className="body-text list-inside list-disc">
                        <li>Tools for GMs to use during and outside of sessions.</li>
                        <li>Generative tools are backed by original content.</li>
                        <li>Regular updates and additions; this is very much a work in progress.</li>
                        <li>Responsiveness to bug reports, feature ideas, and other communication.</li>
                        <li><b>NOTE:</b> Shadowdark Tools page data will <u>only be cleared if you refresh your browser</u>. You can safely navigate between the site's pages without losing your data.</li>
                    </ul>
                </div>
                <div>
                    <h2>Upcoming</h2>
                </div>
                <div>
                    <ul className="body-text list-inside list-disc">
                        <li>General features</li>
                        <ul className="body-text list-inside list-disc">
                            <li className="ml-6">Increase number of items generated, e.g. NPCs, Shops, etc.</li>
                            <li className="ml-6">"Copy All" items to clipboard; either pinned or generated.</li>
                        </ul>
                        <li>Monster Filter features</li>
                        <ul className="body-text list-inside list-disc">
                            <li className="ml-6">Remove individual mosters from the results.</li>
                            <li className="ml-6">Create a custom list of monsters.</li>
                        </ul>
                    </ul>
                </div>
                <div>
                    <h4 className="mt-12">Please Note</h4>
                </div>
                <div className="body-text">
                    Shadowdark Tools is a fan-made, third-party product. (Licensing text is located in the footer and on the About page. Thanks!)
                </div>
            </div>
        </div>
    );

}

export default HomePage;
