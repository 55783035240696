import "./AboutPage.css";
import React from "react";


function AboutPage() {

    return (
        <div>
            <div>
                <div className="about-container">
                    <h2>License</h2>
                    <div>
                        <p className="body-text">
                            Shadowdark Tools is an independent product published under the Shadowdark RPG Third-Party License and is not affiliated with The Arcane Library, LLC.
                            Shadowdark RPG © 2023 The Arcane Library, LLC.
                        </p>
                        <p className="body-text">
                            Core information about <u>Cursed Scroll</u> monsters used with permission from The Arcane Library.
                        </p>
                    </div>
                </div>
                <div className="about-container">
                    <h2>Last Update</h2>
                    <div>
                        <span className="body-label">15-DEC-2024</span>
                        <span className="body-text">
                            &#45; Of little interest to anyone, updating framework and language versions to prepare for new feature work.
                        </span>
                    </div>
                    <div>
                        <span className="body-label">JUL-2023 - NOV-2024</span>
                        <span className="body-text">
                            &#45; Pulled a total <a className="regular" target="_blank" href="https://en.wikipedia.org/wiki/Rip_Van_Winkle" rel="noreferrer">Rip Van Winkle</a> there. Things happen. I'm glad to be back.
                        </span>
                    </div>
                </div>
                <div className="about-container">
                    <h2>Contact</h2>
                    <div>
                        <div className="body-text">
                            Hi, I'm Jesse, creator of the Shadowdark Tools site. I enjoy creating tools for TTRPGs and video games. This is the first one I've released in quite some time.
                        </div>
                        <div className="body-text">
                            Please feel free to reach out with feature requests or bug reports.
                        </div>
                        <div className="body-text">
                            I can be reached on the <a className="regular" target="_blank" href="https://discord.com/channels/558029475837902851/1082831426216534026" rel="noreferrer">Shadowdark Discord</a> where I am Cesidio#1697.
                        </div>

                    </div>
                </div>
                <div className="about-container">
                    <h2>Update History</h2>
                    <div>
                        <ul className="body-text list-inside list-disc">
                            <li>
                                <span className="body-label">23-JUN-2023</span>
                                <span className="body-text">
                                    &#45; Keyword filter, and Clear Filters added to Monster Filter. Various cosmetic changes.
                                </span>
                            </li>
                            <li>
                                <span className="body-label">10-JUN-2023</span>
                                <span className="body-text">
                                    &#45; Cursed Scroll monsters added to Monster Filter.
                                </span>
                            </li>
                            <li>
                                <span className="body-label">06-JUN-2023</span>
                                <span className="body-text">
                                    &#45; Added a Reference page with a Monster Filter. Quickly filter monsters by level, environment, type, attack, or movement.
                                </span>
                            </li>
                            <li>
                                <span className="body-label">02-JUN-2023</span>
                                <span className="body-text">
                                    &#45; Scrolls and Wands can optionally be generated with Priest spells.
                                </span>
                            </li>
                            <li>
                                <span className="body-label">28-MAY-2023</span>
                                <span className="body-text">
                                    &#45; Rival Crawlers added with original tables for names, "known for", secrets, etc.
                                </span>
                            </li>
                            <li>
                                <span className="body-label">24-MAY-2023</span>
                                <span className="body-text">
                                    &#45; Magic Item Generator added with original tables for benefits, curses, features, etc.
                                </span>

                            </li>
                            <li>
                                <span className="body-label">22-MAY-2023</span>
                                <span className="body-text">
                                    &#45; The Planning page is back and the Adventure Ideas and Map Inspiration tools are using original content.
                                    In addition, the data on the planning page will only be cleared if you refresh your browser.
                                </span>
                            </li>
                            <li>
                                <span className="body-label">18-MAY-2023</span>
                                <span className="body-text">
                                    &#45; Random Encounter tool will now roll an Encounter Table die if you choose.
                                    NPC Generator will now generate up to five NPCs at once.
                                </span>
                            </li>
                            <li>
                                <span className="body-label">16-MAY-2023</span>
                                <span className="body-text">
                                    &#45; Shop and Tavern Generators return, using original content.
                                </span>

                            </li>
                            <li>
                                <span className="body-label">14-MAY-2023</span>
                                <span className="body-text">
                                    &#45; NPC Name and NPC Details tools are back, using original content.
                                </span>
                            </li>
                            <li>
                                <span className="body-label">12-MAY-2023</span>
                                <span className="body-text">
                                    &#45; Tools using material in violation of the Third-Party License have been removed.
                                    When they can be backed with original content they will return.
                                </span>
                            </li>
                            <li>
                                <span className="body-label">11-MAY-2023</span>
                                <span className="body-text">
                                    &#45; Tavern Generator added. Random Encounter Log added.
                                    Data on the <b>GM Tools page</b> will persist if you go to another page.
                                    Refreshing browser will still cause the data to be cleared.
                                </span>
                            </li>
                            <li>
                                <span className="body-label">07-MAY-2023</span>
                                <span className="body-text">&#45; Planning page added, with Adventure Ideas and Map Inspiration.</span>
                            </li>
                            <li>
                                <span className="body-label">03-MAY-2023</span>
                                <span className="body-text">&#45; Shop generator added.</span>
                            </li>
                            <li>
                                <span className="body-label">02-MAY-2023</span>
                                <span className="body-text">&#45; Generate Ancestry-based NPC names, code refactor.</span>
                            </li>
                            <li>
                                <span className="body-label">30-APR-2023</span>
                                <span className="body-text">&#45; Added ability to pin and copy generated NPCs.</span>
                            </li>
                            <li>
                                <span className="body-label">28-APR-2023</span>
                                <span className="body-text">&#45; Initial release.</span>
                            </li>
                        </ul>

                    </div>
                </div>
            </div>
        </div>
    );

}

export default AboutPage;
